import moment from "moment";

const tableColsFirmwares = [
  {
    text: "ID",
    value: "id",
    width: 25,
    sort: true
  },
  {
    text: "Чип",
    value: "chip",
    sort: true
  },
  {
    text: "Версия чипа",
    value: "board_version",
    sort: true
  },
  {
    text: "Версия прошивки",
    value: "version",
    sort: true
  },
  {
    text: "Тестовая",
    value: "is_test",
    renderFunction: value => (value.is_test ? "Да" : "Нет"),
    sort: true
  },
  {
    text: "Комментарий",
    value: "comment",
    renderFunction: value =>
      `<div class="d-flex flex-column" style="background-color: inherit;  width: 350px; white-space: break-spaces; text-align: left;"><span>${value.comment}</span></div>`,
    sort: false
  },
  {
    text: "Статус",
    value: "is_active",
    renderFunction: value => (value.is_active ? "Активен" : "Не активен"),
    sort: true
  },
  {
    text: "Дата добавления",
    value: "created_at",
    renderFunction: value => moment.unix(value.created_at).format("DD.MM.YYYY"),
    sort: true
  }
];

export default tableColsFirmwares;
